import React from "react"
import Layout from "../components/layout"
import FAQSection from "../components/home/FAQSection"


const FAQ = () => {
  return (
    <Layout>
<FAQSection>
  
</FAQSection>
    </Layout>
  )
}

export default FAQ
